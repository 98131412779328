import React, { Component } from 'react';
import { Button } from 'react-md';
import renderHTML from 'react-render-html';
import { downloadBpe, parseFilename } from '../Submission.service'
import { Modal, LoadingButton } from '../../../libs/react-mpk/components';
import moment from 'moment';
import { inject, observer } from 'mobx-react'
import { toast, http } from '../../../libs/react-mpk/services';
import numeral from 'numeral'
import t from 'counterpart'
import ReactToPrint from 'react-to-print'

const bpeService = {
  status:(statusCode) => {
    // statusCode = Number(statusCode);
    // return statusCode === 0 ? 'Nihil' : (statusCode === 1 ? 'Kurang Bayar' : (statusCode === 2 ? 'Lebih Bayar' : null));
    return statusCode
  },
  amount: (amount, taxType) => {
    let isDolar = String(taxType).match(new RegExp('badan dolar','ig')) ? true : false;
    let currency = isDolar ? 'US $' : 'Rp,'
    amount =  Number(amount);
    let __amount = numeral(Math.abs(amount)).format( isDolar ? "0,0.0000" : "0,0.00")
    let result = amount < 0 ? ('('+currency+' '+__amount+')') : (currency+' '+__amount);
    return isDolar ? String(result) : String(result).replace(/,/g,";").replace(/\./g,',').replace(/;/g,'.');
  },
  masa:(masa1, masa2) => {
    let nm1 = Number(masa1) < 10 ? `0${masa1}` :`${masa1}`
    let nm2 = Number(masa2) < 10 ? `0${masa2}` :`${masa2}`
    masa1 = t.translate(`months.${nm1}`)
    masa2 = t.translate(`months.${nm2}`)
    return `${masa1} - ${masa2}`
  },
  parseTaxType:(taxType) => {
    return `${taxType}`//"SPT "+String(String(taxType).split('[')[1]).split(']')[0];
  },
  parseTimeStamp: (timeStamp) => {
    if(timeStamp.includes('ICT')){
      timeStamp  = timeStamp.replace('ICT ', '')
      return moment(new Date(timeStamp)).format('DD/MM/YYYY hh:mm:ss');
    } else {
      return timeStamp
    }
  }
}

class BPE extends Component{
  constructor(){
    super();
    this.state = {
      bpe:'',
      defaultBpe:'',
      onProgress: false
    }
  }

  componentDidMount(){
    this.getBpe();
  }

  componentDidUpdate(prevProps){
    let { submission, visible, isBuktiPenerimaanPdf=false } = this.props;
    if(submission !== null && visible && !prevProps.visible){
      let bpe = this.state.defaultBpe;
      let isLebihBayar = submission.ntte.statusspt === '2 - Lebih Bayar'
      
      for(let i = 0 ; i < submission.ntte.npwp.length ; i++){
        bpe = bpe.replace(`*${i}*`, String(submission.ntte.npwp).charAt(i));
      }
      bpe = bpe.replace('*labelBukti*', isBuktiPenerimaanPdf ? 'TANDA TERIMA LAMPIRAN PDF' : 'BUKTI PENERIMAAN ELEKTRONIK')
      if(isBuktiPenerimaanPdf){
        bpe = bpe.replace('class="bpe-hide-file-lapor-pdf"', '');
        const pdfFileNames = submission.pdf.toString().replace(/\,/g, '<br/>')
        bpe = bpe.replace('*pdfFileName*', pdfFileNames);
      }

      if(isLebihBayar){
        bpe = bpe.replace('class="bpe-hide-permohonan-lebih-bayar"', '')
      }

      let metadata = parseFilename(submission.fileName);

      bpe = bpe.replace('*name*', submission.ntte.namawp);
      bpe = bpe.replace('*address*', submission.ntte.alamatwp);
      bpe = bpe.replace('*taxType*', bpeService.parseTaxType(submission.ntte.jenispajak));
      bpe = bpe.replace('*masa*', `${bpeService.masa(submission.ntte.masatahunpajak1, submission.ntte.masatahunpajak2)} / ${submission.ntte.tahunpajak}`);
      bpe = bpe.replace('*pembetulan*', submission.ntte.kodepembetulan);
      bpe = bpe.replace('*status*', bpeService.status(submission.ntte.statusspt).split('- ')[1]);
      bpe = bpe.replace('*amount*', bpeService.amount(submission.ntte.jmlpajak, submission.ntte.jenispajak));
      bpe = bpe.replace('*timeStamp*', bpeService.parseTimeStamp(submission.ntte.tglntte));
      bpe = bpe.replace('*ntpa*', submission.ntpa.replace(/[^0-9]/g, ''));
      bpe = bpe.replace('*ntte*', submission.ntte.nttecode.replace(/[^0-9]/g, ''));
      bpe = bpe.replace('*aspNo*', this.props.envStore.env.aspCode);
      bpe = bpe.replace('*permohonanLebihBayar*', submission.ntte.permohonanLebihBayar);
      this.setState({bpe:bpe, onProgress: false});
    }
  }

  getBpe = async () => {
    let res = await http.get('/static/doc/bpe.html', {}, {}, {
      useDefaultBaseUrl: false,
      useDefaultHost: false
    });
    this.setState({
      defaultBpe:res.data
    })
  }

  download = async () => {
    this.setState({onProgress: true})
    
    try{
      await downloadBpe(this.props.submission.referenceNo);
      this.setState({onProgress: false})
    }catch(error){
      this.setState({onProgress: false})
      toast.errorRequest(error)
    }
  }

  render(){
    let { visible, onRequestClose, ...props } = this.props
    let { onProgress } = this.state;
    return(
      <Modal.Info
        id="nttp-bpe"
        visible={visible}
        onRequestClose={onRequestClose}
        onProgress={onProgress}
        style={{
          maxWidth: 768,
          width: '100%'
        }}
        aria-label="bpe"
        footer={
          <div className="mpk-flex align-center">
            <ReactToPrint
              copyStyle={true}
              pageStyle={{
                height: '100%'
              }}
              content={() => this.printComponent }
              trigger={() => (
                <Button 
                  flat 
                  iconClassName="mdi mdi-printer"
                  disabled={onProgress}
                >
                  {t.translate('column.print')}
                </Button>
              )}
            />
            <LoadingButton
              loading={onProgress}
              flat iconClassName="mdi mdi-download"
              onClick={() => this.download()}
            >
              {t.translate('column.download')}
            </LoadingButton>
            <Button 
              flat 
              onClick={onRequestClose}
              disabled={onProgress}
            >
              {t.translate('column.close')}
            </Button>
          </div>
        }
        {...props}
      >
        <div 
          style={{
            background: 'white',
            height: '100%'
          }}
          ref={ el => (this.printComponent = el)}
        >
          {renderHTML(this.state.bpe)}
        </div>
      </Modal.Info>
    )
  }
}

export default inject('envStore')(observer(BPE));