import React, { useState, useEffect } from "react";
import { DataForm, LoaderInfo } from "../../../../libs/react-mpk/components";
import t from "counterpart";
import { inject, observer } from "mobx-react";
import { storage, options } from "../../../../services";
import moment from "moment";
import { toast } from "../../../../libs/react-mpk/services";
import { getKlu } from "../../Submission.service";
import { find } from "lodash";
import LampiranIII from "./LampiranIII/LampiranIII";

const generate = async (user, env) => {
  let initData = storage.get(storage.keys.SPT_INIT);
  const { npwp, namaWP, tahunPajak, kodePembetulan, flagSuamiIstri } = initData;
  let data = await storage.generate(storage.keys.SPT_1770_PROFILE, {
    npwp,
    namaWP,
    tahunPajak,
    kodePembetulan,
    bulanMulai: "01",
    tahunMulai: String(tahunPajak).substr(2, 2),
    bulanSelesai: "12",
    tahunSelesai: String(tahunPajak).substr(2, 2),
    flagHitung: env.spt.flagHitung[0].value,
    pekerjaan: "",
    KLU: "",
    telepon: "",
    fax: "",
    flagSuamiIstri,
    npwpPasangan: "",
    pp46: false,
    fghs: false,
  });

  return data;
};

const { inputTypes } = DataForm;
const Profile = (props) => {
  const [ready, setReady] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const [klu, setKlu] = useState([]);
  const [show, setShow] = useState({
    npwpPasangan: false,
  });
  const { spt } = props.envStore.env;
  const [months] = useState(options.getMonths());
  const [years] = useState(options.getYears(props.envStore.env.timeStamp));
  const [pembetulans] = useState(options.getPembetulans());
  const [tahunBuku] = useState(
    options.getTahunBuku(props.envStore.env.timeStamp)
  );
  const [tahunBukuSelesai] = useState([
    ...tahunBuku,
    {
      label: String(Number(tahunBuku[0].label) + 1),
      value: String(Number(tahunBuku[0].value) + 1),
    },
  ]);

  const getUpdateData = (data) => {
    const isMandatoryNpwpPasangan =
      ["3", "4"].indexOf(data.flagSuamiIstri) >= 0;
    setShow({ ...show, ...{ npwpPasangan: isMandatoryNpwpPasangan } });
    data.npwpPasangan = isMandatoryNpwpPasangan ? data.npwpPasangan : "";
    data.tahunMulai = String(data.tahunPajak).substr(2, 2);
    // data.tahunSelesai = String(data.tahunPajak).substr(2,2)

    // const endDate = moment(`${data.bulanSelesai}-20${data.tahunSelesai}`, 'MM-YYYY')
    // const startDate = moment(endDate).add(-11, 'month')
    // data.bulanMulai = months[startDate.month()].value
    // data.tahunMulai = String(startDate.year()).substr(2, 2)
    const startDate = moment(
      `${data.bulanMulai}-20${data.tahunMulai}`,
      "MM-YYYY"
    );
    const endDate = moment(startDate).add(11, "month");

    data.bulanSelesai = months[endDate.month()].value;
    data.tahunSelesai = String(endDate.year()).substr(2, 2);

    return data;
  };

  const handleGetKlu = (name) => {
    return new Promise(async (resolve) => {
      try {
        let res = await getKlu(name);
        setKlu(res.data);
        resolve();
      } catch (error) {
        // toast.errorRequest(error)
        resolve();
      }
    });
  };

  useEffect(() => {
    let profile = storage.get(storage.keys.SPT_1770_PROFILE);
    let defData = getUpdateData(profile);

    storage.update(storage.keys.SPT_1770_PROFILE, defData);
    setDefaultData(defData);
    setReady(true);
  }, []);

  return ready ? (
    <DataForm
      baseId="mod-submission-spt-1770-profile"
      className="mpk-margin-C"
      hintMessage={props.errorMessage}
      asDialog={false}
      defaultData={defaultData}
      definitions={[
        {
          render: <tableForm definition />,
        },
        {
          inputType: inputTypes.INPUT,
          label: "NPWP",
          key: "npwp",
          readOnly: true,
        },
        
        {
          inputType: inputTypes.INPUT,
          label: t.translate("column.namaWP"),
          key: "namaWP",
          readOnly: false, // Pelattihan False aslinya true
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate("column.tahunPajak"),
          key: "tahunPajak",
          options: years,
          width: "70%",
          className: "mpk-padding-N padding-right",
          disabled: true,
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate("column.pembetulan"),
          key: "kodePembetulan",
          options: pembetulans,
          width: "30%",
          disabled: true,
        },
        {
          inputType: inputTypes.DIVIDER,
          label: t.translate("column.pembukuan"),
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate("column.bulanMulai"),
          key: "bulanMulai",
          width: "50%",
          options: months.filter((d, i) => i < 7),
          disabled: false,
        },
        {
          inputType: inputTypes.SELECT,
          className: "mpk-padding-N padding-left",
          label: t.translate("column.tahunMulai"),
          key: "tahunMulai",
          width: "50%",
          options: [
            {
              label: "2014",
              value: "14",
            },
            ...tahunBuku,
          ],
          disabled: true,
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate("column.bulanSelesai"),
          key: "bulanSelesai",
          width: "50%",
          options: months,
          disabled: true,
        },
        {
          inputType: inputTypes.SELECT,
          className: "mpk-padding-N padding-left",
          label: t.translate("column.tahunSelesai"),
          key: "tahunSelesai",
          width: "50%",
          options: tahunBukuSelesai,
          disabled: true,
        },
        {
          inputType: inputTypes.SELECT,
          label: t.translate("column.flagHitung"),
          key: "flagHitung",
          options: props.envStore.env.spt.flagHitung,
        },
        // {
        //   inputType: inputTypes.AUTOCOMPLETE,
        //   label: 'Test pekerjaan',
        //   key: 'test_pekerjaan',
        //   data: ['Karyawan Tetap', 'Karyawan Swasta', 'Ibu Rumah Tangga']
        // },
        {
          inputType: inputTypes.AUTOCOMPLETE,
          label: t.translate("column.pekerjaan"),
          key: "pekerjaan",
          className: "mpk-padding-N padding-right",
          width: "80%",
          required: true,
          validation: "min:5",
          data: klu.map((d) => ({
            children: <div style={{ whiteSpace: "wrap" }}>{d.nama}</div>,
            value: d.nama,
          })),
        },
        {
          inputType: inputTypes.INPUT,
          label: "KLU",
          key: "KLU",
          width: "20%",
          validation: "min:5|max:6",
          disabled: true,
          required: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate("column.phone"),
          key: "telepon",
          required: true,
          validation: "min:8|max:16",
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate("column.fax"),
          key: "fax",
          validation: "max:16",
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate("modules.submission.fghs"),
          className:
            "mpk-padding-S padding-top padding-bottom mpk-margin-N margin-bottom",
          key: "fghs",
        },
        {
          inputType: inputTypes.CHECKBOX,
          label: t.translate("modules.submission.pp46"),
          className:
            "mpk-padding-S padding-top padding-bottom mpk-margin-N margin-bottom",
          key: "pp46",
        },

        {
          inputType: inputTypes.SELECT,
          label: t.translate("column.flagSuamiIstri"),
          key: "flagSuamiIstri",
          options: spt.flagSuamiIstri,
          disabled: true,
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate("column.npwpPasangan"),
          key: "npwpPasangan",
          required: true,
          validation: "digits:15",
          show: show.npwpPasangan,
        },
      ]}
      onBeforeChange={(key, value) => {
        if (key === "pekerjaan" && value.length >= 3) handleGetKlu(value);
        if (["telepon", "fax", "KLU"].indexOf(key) >= 0)
          value = value.replace(/\D/g, "");
        else if (["pembetulan"].indexOf(key) >= 0)
          value = Number(value.replace(/\D/g, ""));
        return value;
      }}
      onChange={(data, key) => {
        if (key === "pekerjaan") {
          if (data.pekerjaan.length > 0) {
            let sp = find(klu, { nama: data.pekerjaan });
            if (data.KLU === "" && sp) {
              data.KLU = sp.kode;
            }
            if (!sp && data.KLU !== "") {
              data.KLU = "";
              data.pekerjaan = "";
            }
          } else {
            data.KLU = "";
          }
        }
        // const isMandatoryNpwpPasangan = ['3', '4'].indexOf(data.flagSuamiIstri) >= 0
        // setShow({...show, ...{npwpPasangan: isMandatoryNpwpPasangan}})
        // data.npwpPasangan = isMandatoryNpwpPasangan ? data.npwpPasangan : '';
        // data.tahunSelesai = String(data.tahunPajak).substr(2,2)

        // const endDate = moment(`${data.bulanSelesai}-20${data.tahunSelesai}`, 'MM-YYYY')
        // const startDate = moment(endDate).add(-11, 'month')

        // data.bulanMulai = months[startDate.month()].value
        // data.tahunMulai = String(startDate.year()).substr(2, 2)
        data = getUpdateData(data);

        storage.set(storage.keys.SPT_1770S_PROFILE, data);
        return data;
      }}
      submitLabel={t.translate("column.next")}
      submitIconClassName="mdi mdi-arrow-right"
      onSubmit={(data, callback) => {
        const isMandatoryNpwpPasangan =
          ["3", "4"].indexOf(data.flagSuamiIstri) >= 0;
        if (isMandatoryNpwpPasangan && data.npwp === data.npwpPasangan) {
          callback(new Error(t.translate("sentence.errorNpwpPasangan")));
        } else {
          props.onNextTab();
          storage.set(storage.keys.SPT_1770_PROFILE, data);
          if (data.pp46) {
            storage.update(storage.keys.SPT_1770_DETAIL_LAMP_IIIA, {
              A16: {
                A163: "0",
                A164: "0",
              },
            });
            LampiranIII.updateHeader();
          }
          // props.navigationStore.redirectTo(`/${props.envStore.env.applicationType}/submission/spt-1770/induk`)
          callback(t.translate("sentence.savedItem"), false);
        }
      }}
    />
  ) : (
    <LoaderInfo />
  );
};

Profile.generate = generate;

export default inject(
  "authStore",
  "envStore",
  "navigationStore",
  "temporaryStore"
)(observer(Profile));
