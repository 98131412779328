import React,{useState, useEffect} from 'react'
import { DataForm } from '../../libs/react-mpk/components'
import t from 'counterpart'
import { inject, observer } from 'mobx-react'
import { upload, getUploadAggrement } from './Certificate.service'
import renderHTML from 'react-render-html'
import { toast } from '../../libs/react-mpk/services'
import { Link } from 'react-router-dom'
import { Button, AutoComplete } from 'react-md'

const { inputTypes } = DataForm
const Upload = ({
  visible         = false,
  onRequestClose  = () => {},
  ...props
}) => {
  const [agreement, setAgreement] = useState(null)
  const handleSubmit = async (data, callback) => {
    if(data.agree){
      try {
        const res = await upload(data)
        props.temporaryStore.setProperties('npwp', res.data)
        onRequestClose()
        callback()
      } catch (error) {
        callback(error, true, false)
      }
    }else{
      callback(new Error(t.translate('modules.certificate.form.notAgreeAlert')), true, false)
    }
  }

  useEffect(async () => {
    try {
      if(!agreement){
        let res = await getUploadAggrement()
        setAgreement(res.data)
      }
    } catch (error) {
      toast.errorRequest(error)
    }
  })

  return (
    <DataForm
      baseId="mod-certificate-upload"
      title={t.translate(`modules.certificate.upload.title`)}
      hintMessage={t.translate(`modules.certificate.upload.hintText`)}
      asDialog={true}
      visible={visible}
      onRequestClose={onRequestClose}
      defaultData={{
        file: null,
        password: ''
      }}
      definitions={[
        {
          inputType: inputTypes.FILE_INPUT,
          label: t.translate(`column.selectFile`),
          key: 'file',
          accept: '.p12'
        },
        {
          inputType: inputTypes.INPUT,
          label: t.translate('modules.certificate.upload.certPassword'),
          helpText: t.translate('modules.certificate.upload.passwordHint'),
          key: 'password',
          type: 'password',
          required: true
        },
        {
          inputType: DataForm.inputTypes.DIVIDER,
          label: t.translate('column.agreement')
        },
        {
          render:(
            <div 
              className="mpk-border border-all thin solid dark mpk-padding-N padding-all agrement mpk-full full-width"
              style={{
                lineHeight: '24px',
                background: '#fef6f4'
              }}
            >
              {agreement && renderHTML(agreement)}
            </div>
          )
        },
        {
          inputType: DataForm.inputTypes.CHECKBOX,
          label: t.translate('modules.certificate.form.agree'),
          key: 'agree'
        }
      ]}
      onBeforeChange={(key, value) => {
        // Perubahan Simulasi
        // if(key === 'file') {
        //   let name = value.name.split('.')[0]
        //   let isPusat = name.substr(name.length - 3, 3) === '000'
        //   if(isPusat) return value;
        //   else {
        //     toast.error(t.translate('modules.certificate.upload.mustHOAlert'))
        //     return null
        //   }
        // } else {
        //   return value
        // }
        // Perubahan Simulasi
        if(key === 'file') {
            let name = value.name.split('.')[0]
            let isPusat = name.substr(name.length - 3, 3) === '000'
            if(isPusat) return value;
            else {
              // toast.error(t.translate('modules.certificate.upload.mustHOAlert'))
              return value
            }
          } else {
            return value
          }
      }}
      onSubmit={handleSubmit}
      showCancel={true}
      additionalFooter={(
        <a
          href={props.envStore.env.documentation.url}
          target="blank"
        >
          <Button
            theme="primary"
            themeType="outline"
            className="mpk-margin-N margin-right margin-left"
          >
            {t.translate('column.guide')}
          </Button>
        </a>
      )}
      {...props}
    />
  )
}

export default inject('temporaryStore', 'envStore')(observer(Upload))
