import React, {useState, useEffect} from 'react'
import { DataForm, LoaderInfo } from '../../../../libs/react-mpk/components'
import t from 'counterpart'
import * as service from '../../Submission.service'

import { inject, observer, useLocalObservable } from 'mobx-react'
import { toast } from '../../../../libs/react-mpk/services'
import { find } from 'lodash'
import { file, storage } from '../../../../services'
import { uid } from 'uid'
import moment from 'moment'
import renderHTML from 'react-render-html'
import { dataFormatter, Formatter, unformatNpwp } from '../../../../services/utils.service'

const{ inputTypes } = DataForm
const Commit = ({
  visible         = false,
  onRequestClose  = () => {},
  onError         = () => {},
  submission      = null,
  ...props
}) => {
  const handleSetPdfDefinitions = (code, profile) => {
    profile = profile || storage.get(storage.keys[`SPT_${code}_PROFILE`])
    
    if(profile){
      switch(code){
        case '1770':
          return props.envStore.env.pdf[code].filter(d => (typeof d.flagHitung === 'string' ? d.flagHitung === String(profile.flagHitung) : d))
        default:
          return props.envStore.env.pdf[code]
      }
    } else return []
  }

  const [spt, setSpt] = useState(null)
  const [sptData, setSptData] = useState(null)
  const [code, setCode] = useState('1770')
  const [saveOnly, setSaveOnly] = useState(true)
  const [uploadIdPdf, setUploadIdPdf] = useState([])
  const [pdfDefinitions, setPdfDefinitions] = useState([])
  const [committing, setCommitting] = useState(false)
  const [statusMessage, setStatusMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const callPerubahan = (data)=> {
    // Perubahan Simulasi
    try {
      var newSptData = {
        ...sptData
      }
      newSptData.induk.namaWPPelapor = newSptData.profile.namaWP
      newSptData.induk.npwpPelapor = newSptData.profile.npwp
      setSptData(newSptData)  
    } catch(e){
      
    }
    // Perubahan Simulasi
    return data
  }
  
  const handleSubmitSpt = async (data, callback) => {
    data = callPerubahan(data)
    if(!data) new Error(t.translate('modules.submission.sptForm.commit.emptySPT'))
    else {
      try {
        props.temporaryStore.setProperties('sptErrorData', null)

        const resSpt = await service[`post${code}`](sptData)
        if(saveOnly){
          onRequestClose()
          if(props.onSuccess) props.onSuccess(resSpt.data)
          props.modalStore.showConfirm({
            title: t.translate('modules.submission.sptForm.save.title'),
            children: t.translate('modules.submission.sptForm.save.confirmMessage'),
            onSubmit: (callback) => {
              props.navigationStore.redirectTo(`/${props.envStore.env.applicationType}/submissions`)
              callback()
            }
          })
        } else {
          setSpt(resSpt.data.spt)
          setLoading(false)
          callback(null, false, false)
        }
      } catch (error) {
        let isErrorData = false
        if(error.response){
          let { data } = error.response;
          if(data && data.errors){
            isErrorData = true
            let { errors } = data;
            let errorData = {}
            for(let err of errors){
              let keys = Object.keys(err)
              let key = keys[0]
              errorData[key] = err[key].map(e => (e.error))
            }
            props.temporaryStore.setProperties('sptErrorData', errorData)
          }//else toast.errorRequest(error)
        }

        // onError(error)
        callback(isErrorData ? new Error(t.translate('sentence.errorData')) : error)
        onRequestClose()
      }
    }
  }

  const handleCommit = async (data, callback) => {
    // Perubahan Simulasi
    data = callPerubahan(data)
    if(data.agree){
      setCommitting(true)
      try {
        const { name, referenceNo } = spt
        await handleUploadPdf(data)
        setStatusMessage(t.translate('modules.submission.sptForm.commit.statusCommit'))
        await service.commitSPT(name)
        callback(t.translate('modules.submission.sptForm.commit.success'), false)

        if(props.onSuccess) props.onSuccess()
        if(props.onBack) props.onBack()
        else onRequestClose()
        storage.clearAll()
      } catch (error) {
        setCommitting(false)
        setStatusMessage('')
        callback(error)
      }
    } else {
      callback(new Error(t.translate('modules.submission.sptForm.notAgreeAlert')))
    }
  }

  const handleUploadPdf = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { chunkSize } = props.envStore.env
        for(let def of pdfDefinitions){
          if(data[def.pdfType]){
            let f = data[def.pdfType]
            let responseUpload = null

            if(f){
              setStatusMessage(t.translate(`modules.submission.sptForm.commit.statusMessagePdf`).replace('*name', f.name))
              let chunking = await file.chunk(f, chunkSize, true, true)
              let id = `${moment(props.envStore.env.timeStamp).format('YYYYMMDDHHmmss')}${uid(2)}`
              for(let i = 0 ; i < chunking.blobs.length ; i++){
                let bl = chunking.blobs[i]
                let data = {
                  id,
                  name: f.name,
                  pdfType: def.pdfType,
                  size: f.size,
                  offset: i,
                }

                if(responseUpload) data.uploadId = responseUpload.uploadId
                let res = await service.uploadPDF(spt.name, bl, data)
                if(!responseUpload){
                  uploadIdPdf.push(res.data.uploadId)
                  setUploadIdPdf(uploadIdPdf)
                }
                responseUpload = responseUpload || res.data
              }
            }
          }
        }
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  const checkingRequired = (d) => {
    let { checkList } = sptData.induk
    let isRequired = code === '1770S'  
      ? String(sptData.induk.E.flagE16) === '2'
      : d.required
    
    // if(['RK', 'LK'].indexOf(d.pdfType) < 0){
    if(!d.required){
      if(d.checklist){
        for(let c of d.checklist){
          if(checkList[c.key] && checkList[c.key] === '1'){
            isRequired = true
            break
          }
        }
      }
      // }
    }

    return isRequired
  }

  const handlePdfDefaultValue = pdfType => {
    let uploadId = find(uploadIdPdf, {pdfType})
    if(uploadId) return uploadId
    else return null
  }

  useEffect(async () => {
    if(visible){
      try{
        if(submission){
          setLoading(true)
          let res = await service.getRawData(submission.fileName)
          let { data, code, submissionName, referenceNo, uploadIdPdf } = res.data
          setCode(code)
          setSptData(data)
          setPdfDefinitions(handleSetPdfDefinitions(code, data.profile))
          setSpt({name: submissionName, referenceNo})
          setUploadIdPdf(uploadIdPdf)
          setSaveOnly(false)
          setCommitting(false)
          setLoading(false)
        } else {
          setCode(props.code)
          setSaveOnly(props.saveOnly)
          setSptData(props.sptData)
          setPdfDefinitions(handleSetPdfDefinitions(props.code, props.sptData.profile))
          setUploadIdPdf([])
          setCommitting(false)
          // setStatusMessage('')
        }

      }catch(error){
        toast.errorRequest(error)
        onRequestClose()
      }
    } else {
      setTimeout(() => {
        setSpt(null)
        setSptData(null)
        setPdfDefinitions([])
      }, 500)
    }
  }, [visible])

  return (
    <DataForm
      baseId="mod-submission-sptform-commit"
      title={t.translate(
        saveOnly 
          ? 'modules.submission.sptForm.save.title'
          : `modules.submission.sptForm.commit.title`
      )}
      hintMessage={committing ? null : spt ? t.translate('modules.submission.sptForm.commit.pdfHint') : ''}
      asDialog={true}    
      visible={visible}
      onRequestClose={onRequestClose}
      defaultData={{}}
      definitions={
        loading 
        ? [{render: <LoaderInfo>{t.translate('sentence.loadingData')}</LoaderInfo>}]
        : committing 
          ? [
            {
              render: (
                <div className="mpk-align align-center mpk-font size-L">
                  {statusMessage}
                </div>
              )
            }
          ]
          : spt 
            ? [...pdfDefinitions.map(d => ({
              inputType: inputTypes.FILE_INPUT,
              label: d.label,
              key: d.pdfType,
              value: handlePdfDefaultValue(d.pdfType),
              required: checkingRequired(d),
              // helpText: t.translate('modules.submission.sptForm.commit.pdfFileNameInfo').replace('*name', `${spt.name}${d.pdfType}.pdf`),
              helpText: d.checklist ? (
                <div className="mpk-padding-N padding-left padding-right">
                  <p>{t.translate('modules.submission.sptForm.commit.attachmentItemInfo')}</p>
                  <ul className="mpk-margin-NONE margin-all mpk-padding-N padding-left">
                    {d.checklist.map((c, i) => (
                      <li key={`commit-pdf-${i}`}>
                        {t.translate(`modules.submission.sptForm.${code}.Induk.${c.label}`)}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null,
              accept:'.pdf'
            })), {
              inputType: inputTypes.CHECKBOX,
              label: t.translate('modules.submission.sptForm.commit.submitHint'),
              className: 'mpk-margin-N margin-top',
              key: 'agree'
            }] 
            : [{render: (
              <div>{renderHTML(t.translate(`modules.submission.sptForm.save.saveHint`))}</div>
            )}]
      }
      onBeforeChange={(key, value) => {
        return new Promise(async (resolve, reject) => {
          try{
            if(value){
              // const expectedFilename = `${spt.name}${key}.pdf`
              // if(value.name !== expectedFilename){
              //   toast.error(t.translate('modules.submission.sptForm.commit.unexpectedPDFFilename').replace('*name', expectedFilename))
              //   return null
              // } else {
                if(key === 'LK' || key === 'RK'){
                  let LK = find(pdfDefinitions, {pdfType: 'LK'})
                  let RK = find(pdfDefinitions, {pdfType: 'RK'})
                  if(LK) LK.required = false
                  if(RK) RK.required = false
                  setPdfDefinitions(pdfDefinitions)
                } 
                resolve(value)
              // }
            }else{
              let existingUploadId = handlePdfDefaultValue(key)
              if(existingUploadId) {
                await service.deletePDF(spt.name, existingUploadId.uploadId)
                setUploadIdPdf(uploadIdPdf.filter(d => (d.pdfType !== key)))
              }
              resolve(value)
            }
          }catch(error){
            toast.errorRequest(error)
          }
        })
      }}
      onChange={(data, key) => {
        if(key === 'LK' || key === 'RK'){
          let LK = find(pdfDefinitions, {pdfType: 'LK'})
          let RK = find(pdfDefinitions, {pdfType: 'RK'})
          if(!data.RK && !data.LK){
            RK.required = true
            LK.required = true
          }else{
            if(key === 'LK') LK.required = data.RK === null
            else if(key === 'RK') RK.required = data.LK === null
            setPdfDefinitions(pdfDefinitions)
          }
        } 
        return data
      }}
      onSubmit={spt ? handleCommit : handleSubmitSpt}
      submitLabel={t.translate(
         spt 
          ? 'column.next' 
          : saveOnly ? 'column.save' : 'column.submit'
      )}
    />
  )
}

export default inject('envStore', 'navigationStore', 'temporaryStore', 'modalStore')(observer(Commit))
