import React, { useEffect} from 'react'
import { Modal } from '../../../libs/react-mpk/components'
import { inject, observer } from 'mobx-react'
import { clone } from 'lodash'
import { storage } from '../../../services'
import { number, toast } from '../../../libs/react-mpk/services'
import moment from 'moment'
import { find } from 'lodash'
import t from 'counterpart'
import './MPN.scss'

const MPN = ({
  code            = '1770',
  visible         = false,
  onRequestClose  = () => {},
  ssp             = null,
  billingCode     = null,
  ...props
}) => {
  useEffect(() => {
    if(window.mpnPajakku && visible === true){
      setTimeout(() => {
        window.mpnPajakku.render('mpn-container', clone({
          clientId: props.envStore.env.apiGateway.clientId,
          widgetClientId: props.envStore.env.widgetInterface.mpn.clientId,
          accessToken: props.authStore.getAccessToken(),
          user: JSON.parse(JSON.stringify(props.authStore.user)),
          cancelation: true,
          autoInquiry: true,
          ssp, billingCode
        }), async (event) => {
          console.log(event.type, "WUT EVENT")
          if(event.type === 'on-payment-data'){
            let data = JSON.parse(event.body);
          } else if(event.type === 'id-billing-created'){
            let tanggalSSP = moment().format('YYYY-MM-DD')
            let { daftarSSP } = storage.get(storage.keys[`SPT_${code}_DETAIL_SSP`])
            let data = JSON.parse(event.body);

            daftarSSP.push({
              kodeMataAnggaranPenerimaan: '411125',
              kodeJenisSetoran: '200',
              jumlahSSP: number.format(data.amount),
              billingCode: data.idBilling,
              tanggalSSP,
              ntpn: ""
            })

            storage.update(storage.keys[`SPT_${code}_DETAIL_SSP`], {daftarSSP})
            toast.info(t.translate('sentence.sspCreated').replace('*billingCode', data.idBilling))
            // onRequestClose()
          } else if(event.type === 'on-select-payment-data'){
            let data = JSON.parse(event.body)
            if(data.paymentCode === billingCode && data.ntpn){
              let induk = storage.get(storage.keys[`SPT_${code}_INDUK`])
              let { daftarSSP } = storage.get(storage.keys[`SPT_${code}_DETAIL_SSP`])
              let sspItem = find(daftarSSP, {billingCode: data.paymentCode})
              if(sspItem){
                let tanggalSSP = moment(data.localDatetime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
                let idx = daftarSSP.indexOf(sspItem)
                daftarSSP[idx].ntpn = data.ntpn
                daftarSSP[idx].jumlahSSP = number.format(data.amount)
                daftarSSP[idx].tanggalSSP = tanggalSSP
                storage.update(storage.keys[`SPT_${code}_DETAIL_SSP`], {daftarSSP})
                toast.success(t.translate('sentence.setNtpn').replace('*billingCode', billingCode).replace('*ntpn', data.ntpn))
                window.dispatchEvent(new CustomEvent('mpn-update'))

                let { E } = induk
                if(['', null].indexOf(E[code === '1770' ? 'E19TanggalLunas' : 'E16TanggalLunas']) >= 0){
                  E[code === '1770' ? 'E19TanggalLunas' : 'E16TanggalLunas'] = tanggalSSP
                  storage.update(storage.keys[`SPT_${code}_INDUK`], {E})
                }
              }
            }
          }else if(event.type === 'on-cancel'){
            onRequestClose()
          }
        })
      }, 200)
    }
  }, [visible])

  return (
    <Modal
      id="mpn-widget-dialog"
      visible={visible}
      onRequestClose={onRequestClose}
      disableFocusOnMount={true}
      noFooter={true}
      noPadding={true}
      style={{
        width: '100%',
        maxWidth: 400
      }}
    >
      <div id="mpn-container" />
    </Modal>
  )
}

export default inject('envStore', 'authStore', 'temporaryStore')(observer(MPN))
